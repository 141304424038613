import { useSearchParams } from 'react-router-dom';
import './pages.css';

function Connect() {
  let [params] = useSearchParams();
  let hostname = params.get('hostname');
  const token = params.get('authToken');

  console.log("TOKEN: ", token);  
  
  return (
    <div className='iframe-container'>
      { hostname ? (
          <iframe
            id='viewer'
            title='NICE DCV client'
            allowFullScreen
            src={`https://${hostname}:8443/?authToken=${token}#console`}
          ></iframe>
        ) : (
          <p>No hostname provided.</p>
        )
      }
    </div>
  );
}

export default Connect;