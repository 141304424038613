import { Auth } from 'aws-amplify';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import ConstructionIcon from '@mui/icons-material/Construction';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './pages.css';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  }
});

const menuStyle = {
  textDecoration: 'none',
  margin: '6px',
  color: 'white',
  fontSize: '16px',
}

function Layout(props) {
  const [user, setUser] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentUserInfo().then((user) => setUser(user));
  },[]);
  
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Box 
          height='100vh' 
          sx={{
            position: 'relative',
            minWidth: 1000,
            minHeight: 625,
            backgroundColor: 'background.paper',
            color: 'text.secondary',
            boxShadow: 1,
            overflow: 'hidden',
          }}   
        >
          <div className="logout">
            <Button 
              variant="text"
              onClick={() => props.logout()}
              size="small"
            >Sign out</Button>
          </div>      
          <div className="user">
            Hello {user.username}
          </div>
          <div className="menu">
            <DesktopWindowsIcon onClick={() => navigate("/")} sx={{ mt: 1, ml: 1 }}/>
            <Link to="/" style={menuStyle}>Your desktops</Link>  
            <ConstructionIcon onClick={() => navigate("/create")} sx={{ mt: 1, ml: 1 }}/>
            <Link to="/create" style={menuStyle}>Create desktop</Link>  
          </div>

          <Outlet />
        </Box>
      </ThemeProvider>
    </>
  )
};

export default Layout;