import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import List from './pages/ListView';
import Create from './pages/CreateView';
import Connect from './pages/ConnectView';
import Nothing from './pages/Nothing';
import './App.css';

// Auth libraries
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import config from './config.json';
const authConfig = config.Cognito;
// const REGION = 'eu-west-2';

// re-use existing auth backend
Amplify.configure({
  Auth: {
    region: authConfig.AWS_Region,
    userPoolId: authConfig.CognitoUserPool,
    userPoolWebClientId: authConfig.AppClientID,
    identityPoolId: authConfig.CognitoIdentityPool,
  }
});


// re-use existing auth backend
//Amplify.configure({
//  Auth: {
//    region: REGION,
//    userPoolId: 'eu-west-2_TNWvlrgVg',
//    userPoolWebClientId: '2ph10hcis3mi4l1920grh62470',
//    identityPoolId: 'eu-west-2:eb73e323-8367-42aa-ba72-564fdbf0a6f9',
//  }
//});

function App({signOut, user}) {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Layout logout={signOut} /> }>
          <Route index element={ <List /> }/>
          <Route path="create" element={ <Create /> }/>
          <Route path="connect" element={ <Connect /> }/>
          <Route path="*" element={ <Nothing /> }/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default withAuthenticator(App, {hideSignUp: true});
