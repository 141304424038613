import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import './pages.css';
import config from '../config.json';
const apiConfig = config.API_Gateway;
const baseURL = apiConfig.API_GATEWAY_URL;
//const baseURL = 'https://07un064t5f.execute-api.eu-west-2.amazonaws.com/prod/';
const resource = 'list_types';
const launchResource = 'create';

function CreateDialog(props) {
  const { closeDialog, open, details } = props;
  const [launching, setLaunching] = useState(false); 
  const navigate = useNavigate();

  const handleClose = () => {
    closeDialog();
  }

  const handleLaunch = () => {
    console.log("LAUNCHING");
    setLaunching(true);
    closeDialog();
    Auth.currentSession().then((session) => {
      console.log("SESH: ", session);
      axios.post(baseURL+launchResource, null, {
        headers: {
          'Authorization': session.idToken.jwtToken
        },
        params: {
          'InstanceType': details.InstanceType,
          'vRAM': details.vRAM,
          'GPU': details.GPU,
          'WavelengthZoneGeo': details.WavelengthZoneGeo,
        },
      }).then((response) => {
          console.log(response);
          navigate('/');
        });
    });
  }

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={launching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: "center", typography: 'body1' }}>Confirm launch? </DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ textAlign: "center" }} variant="body2" gutterBottom>
            {details.InstanceOS} {"("+details.InstanceType+")"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small">Cancel</Button>
          <Button onClick={handleLaunch} size="small">Launch</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Create() {
  const [types, setTypes] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [launchDetails, setLaunchDetails] = useState({});

  const handleRowClick = (data) => {
    console.log("WANT TO CREATE: ", data);
    setLaunchDetails(data);
    setDialogOpen(true);
  }

  const handleClose = (value) => {
    setDialogOpen(false);
  }

  useEffect(() => {
    Auth.currentSession().then((session) => {
      axios.get(baseURL+resource, {
        headers: {
          'Authorization': session.idToken.jwtToken,
        }
      }).then((response) => {
          setTypes(response.data);
        })
    });
  },[]);

  return (
    <Box>
      <h4 style={{ marginLeft: 8 }}>Virtual desktop catalog</h4>
      <Typography sx={{ marginLeft: 1 }} variant="body2" gutterBottom>
        Behold, below are available virtual desktops to provision. Click on a row, confirm your choice, and grab a coffee while your desktop is created.
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontSize: 14}}>OS</TableCell>
              <TableCell sx={{fontSize: 14}} align="center">Cost</TableCell>
              <TableCell sx={{fontSize: 14}} align="center">Type</TableCell>
              <TableCell sx={{fontSize: 14}} align="center">vCPUs</TableCell>
              <TableCell sx={{fontSize: 14}} align="center">Instance type</TableCell>
              <TableCell sx={{fontSize: 14}} align="center">RAM</TableCell>
              <TableCell sx={{fontSize: 14}} align="center">VRAM</TableCell>
              <TableCell sx={{fontSize: 14}} align="center">GPU</TableCell>
              <TableCell sx={{fontSize: 14}} align="center">WavelengthZoneGeo</TableCell>              
            </TableRow>
          </TableHead>
          <TableBody>
            {types.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => handleRowClick(row)}
              >
                <TableCell sx={{fontSize: 12}} component="th" scope="row">{row.InstanceOS}</TableCell>
                <TableCell sx={{fontSize: 12}} align="center">{row.Cost}</TableCell>
                <TableCell sx={{fontSize: 12}} align="center">{row.InstanceName}</TableCell>
                <TableCell sx={{fontSize: 12}} align="center">{row.vCPUs}</TableCell>
                <TableCell sx={{fontSize: 12}} align="center">{row.InstanceType}</TableCell>
                <TableCell sx={{fontSize: 12}} align="center">{row.RAM}</TableCell>
                <TableCell sx={{fontSize: 12}} align="center">{row.vRAM}</TableCell>
                <TableCell sx={{fontSize: 12}} align="center">{row.GPU}</TableCell>
                <TableCell sx={{fontSize: 12}} align="center">{row.WavelengthZoneGeo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateDialog
        open={dialogOpen}
        closeDialog={handleClose}
        details={launchDetails}
      />
    </Box>
  );
}

export default Create;