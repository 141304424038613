import { Auth } from 'aws-amplify'; 
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import './pages.css';
import config from '../config.json';
const apiConfig = config.API_Gateway;
const baseURL = apiConfig.API_GATEWAY_URL;
//const baseURL = 'https://07un064t5f.execute-api.eu-west-2.amazonaws.com/prod/';

function ActionDialog(props) {
  const { closeDialog, open, instance, deleteInstance, modifyInstanceStatus, token } = props;
  const [working, setWorking] = useState(false);
  const [creds, setCreds] = useState('*******');
  const navigate = useNavigate();

  const callAPI = (method) => {
    console.log(`Calling ${method} on ${instance}.`);

    if(method === 'delete') {
      setWorking(true);
    }
    
    Auth.currentSession().then((session) => {
      axios.get(baseURL+method, {
        headers: {
          'Authorization': session.idToken.jwtToken
        },
        params: {
          'InstanceID': instance.InstanceID,
        },
      }).then((response) => {
          if(method === 'connect'){
            setCreds(response.data.pw);
            navigator.clipboard.writeText(response.data.pw);
          }
          setWorking(false);
        });
    });
  }

  const getPW = () => {
    callAPI('connect');
    document.getElementById("creds").style.display = "inline";
  }

  const handleClose = () => {
    closeDialog();
  }

  const handleState = (state) => {
    switch (state.toLowerCase()) {
      case 'connect':
        console.log("Connecting to instance: ", instance.R53 + "-" + instance.InstanceID);
        if (instance.InstanceState === 'running') {
          console.log("IS THIS SET?", token);
          navigate(`/connect?authToken=${token}&hostname=${instance.R53}`);
        } else {
          alert('The requested virtual desktop is not in a RUNNING state. If you just launched the desktop, give it another minute or two. Use the refresh link to fetch the latest status of your desktops.');
          closeDialog();
        }
        break;
      case 'delete':
        console.log("Deleting instance: ", instance.R53 + "-" + instance.InstanceID);
        callAPI('delete');
        deleteInstance(instance.InstanceID);
        closeDialog();
        setWorking(false);
        break;
      case 'start':
        console.log("Starting instance: ", instance.R53 + "-" + instance.InstanceID);
        callAPI(state);
        modifyInstanceStatus(instance.InstanceID, 'starting');
        closeDialog();
        break;
      case 'stop':
        console.log("Stopping instance: ", instance.R53 + "-" + instance.InstanceID);
        callAPI(state);
        modifyInstanceStatus(instance.InstanceID, 'stopping');
        closeDialog();
        break;
      default:
        console.log("Unrecognized state change: ", state);
        break;
    }
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={working}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: "left", typography: 'body1', fontWeight: 'bold' }}>Actions menu:</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body2" gutterBottom>
            Host url:
            <span style={{ color: '#6fbf73', marginLeft: '6px', }}>{instance.R53}</span>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Host user:
            <span style={{ color: '#6fbf73', marginLeft: '6px', }}>{instance.UserName}</span>
          </Typography>
          <div id="creds">
            <Typography align="left" variant="body2">
              Host pw:
              <span style={{ color: '#6fbf73', marginLeft: '6px', }}>{creds}</span>
              <span style={{ fontStyle: 'italic', marginLeft: '6px' }}>(copied to clipboard)</span>
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => getPW()} size="small">Get PW</Button>
          <Button onClick={() => handleState('connect')} size="small">Connect</Button>
          <Button onClick={() => handleState('start')} size="small">Start</Button>
          <Button onClick={() => handleState('stop')} size="small">Stop</Button>
          <Button onClick={handleClose} size="small">Cancel</Button>
          <Button onClick={() => handleState('delete')} size="small" sx={{ color: '#f50057'}}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function List() {
  const [instances, setInstances] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [instanceDetails, setInstanceDetails] = useState({});
  const [render, setRender] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    Auth.currentSession().then((session) => {
      setToken(session.idToken.jwtToken);
      axios.get(baseURL+'list_instances', {
        headers: {
          'Authorization': session.idToken.jwtToken
        }
      }).then((response) => {
          setInstances(response.data);
        });
    });
  },[render]);

  // remove instance from state
  const deleteInstance = (instance) => {
    console.log("DELETING: ", instance);
    const newList = instances.filter(item => item['InstanceID'] !== instance);
    setInstances(newList);
  }

  const modifyInstanceStatus = (instanceID, state) => {
    console.log(`MODIFYING: ${instanceID} to ${state}`);
    const newList = instances.map((obj) => {
      if(obj.InstanceID === instanceID) {
        return {...obj, InstanceState: state};
      }
      return obj;
    });
    setInstances(newList);
  }

  const handleRowClick = (data) => {
    console.log("CLICK EVENT: ", data);
    setInstanceDetails(data);
    setDialogOpen(true);
  }

  const handleClose = (value) => {
    setDialogOpen(false);
  }

  const handleRefresh = () => {
    console.log("REFRESH CLICKED");
    setRender(!render);
  }

  return (
    <Box>
      <h4 style={{ marginLeft: 8 }}>Your virtual desktops</h4>
      <div style={{
        display: 'inline',
        alignItems: 'center',
      }}>
        <RefreshIcon 
          sx={{ float: 'right', mr: 1 }}
          onClick={() => handleRefresh()}
        />
        <Typography 
          sx={{ float: 'right', mr: 1 }} 
          onClick={() => handleRefresh()} 
          variant="body2"
        >
          Refresh
        </Typography>
        <Typography sx={{ ml: 1 }} variant="body2">
          Ahoy, below are your provisioned virtual desktops. Click on a host below to manage or connect.
        </Typography>
        
      </div>
      <TableContainer component={Paper}>
  <Table sx={{ minWidth: 400 }} size="small">
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontSize: 14 }}>Host</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">Status</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">OS</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">Type</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">vCPUs</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">RAM</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">VRAM</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">GPU</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">Cost</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">Username</TableCell>
        <TableCell sx={{ fontSize: 14 }} align="center">WavelengthZoneGeo</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {instances.map((row, index) => (
        <TableRow
          hover
          key={index}
          sx={{ ':last-child td, :last-child th': { border: 0 } }}
          onClick={() => handleRowClick(row)}
        >
          <TableCell sx={{ fontSize: 12 }} component="th" scope="row">
            {row.R53}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {(row.InstanceState).toUpperCase()}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {row.InstanceData.InstanceOS}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {row.InstanceData.InstanceName}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {row.InstanceData.vCPUs}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {row.InstanceData.RAM}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {row.InstanceData.vRAM}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {row.InstanceData.GPU}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {row.InstanceData.Cost}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {row.UserName}
          </TableCell>
          <TableCell sx={{ fontSize: 12 }} align="center">
            {row.WavelengthZoneGeo}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
      <ActionDialog
        open={dialogOpen}
        closeDialog={handleClose}
        instance={instanceDetails}
        deleteInstance={deleteInstance}
        modifyInstanceStatus={modifyInstanceStatus}
        token={token}
      />
    </Box>
  );
}

export default List;